import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { getIntegrationBlocks } from 'services/parse'
import { publishIntegrationCustomBlock } from 'services/parse/CustomBlocks'
import {
    selectAllActiveUserIntegrationsObj,
    selectAllInactiveUserIntegrationsObj,
} from 'store/Settings/user-integration-slice'

// Creating an entity adapter for integration blocks
const integrationBlocksAdapter = createEntityAdapter({
    selectId: (block) => block.id,
    sortComparer: (a, b) => b.lastSavedAt - a.lastSavedAt,
})

export const fetchIntegrationBlocks = createAsyncThunk(
    'integrationBlocks/fetchIntegrationBlocks',
    async (_, { rejectWithValue }) => {
        try {
            const response = await getIntegrationBlocks()
            if (!response.customBlocks) {
                const errorData = await response.json()
                return rejectWithValue(errorData.msg || 'Server Error')
            }

            return response.customBlocks.map((block) => ({
                ...block.attributes,
                id: block.id,
            }))
        } catch (error) {
            return rejectWithValue(error.message || 'An error occurred')
        }
    }
)

export const publishIntegrationBlock = createAsyncThunk(
    'customBlocks/publishIntegrationBlock',
    async ({ id }, { rejectWithValue, dispatch, getState }) => {
        const state = getState()
        const integrationBlock = selectIntegrationBlockById(state, id)
        const { releaseNotes } = integrationBlock.partnerFields || []
        const currentRelease = { ...releaseNotes[0] }
        const updatedRelease = { ...currentRelease, status: 'published' }
        const updatedReleaseNotes = [updatedRelease, ...releaseNotes.slice(1)]
        const requestBody = {
            id,
            partnerFields: {
                releaseNotes: updatedReleaseNotes,
                status: 'published',
            },
            appId: integrationBlock.app.id,
        }
        try {
            const response = await publishIntegrationCustomBlock(requestBody)

            return { block: response, id: response.id }
        } catch (error) {
            console.log(error)

            return rejectWithValue(error.message[0])
        }
    }
)

const integrationBlocksSlice = createSlice({
    name: 'integrationBlocks',
    initialState: integrationBlocksAdapter.getInitialState({
        loading: false,
        error: null,
        initialized: false,
    }),
    reducers: {
        partnerBlockAddOne: integrationBlocksAdapter.addOne,
        partnerBlockUpdateOne: (state, action) => {
            return integrationBlocksAdapter.updateOne(state, {
                id: action.payload.id,
                changes: action.payload.changes,
            })
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchIntegrationBlocks.pending, (state) => {
                state.loading = true
                state.error = null
            })
            .addCase(fetchIntegrationBlocks.fulfilled, (state, action) => {
                state.loading = false
                state.error = null
                state.initialized = true
                // Sort the blocks by updatedAt field
                const sortedBlocks = action.payload.sort(
                    (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
                )

                integrationBlocksAdapter.setAll(state, sortedBlocks)
            })
            .addCase(fetchIntegrationBlocks.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(publishIntegrationBlock.fulfilled, (state, action) => {
                integrationBlocksAdapter.upsertOne(state, action.payload.block)
            })
            .addCase(publishIntegrationBlock.rejected, (state, action) => {
                state.error = action.payload
            })
    },
})

export const {
    selectAll: selectAllIntegrationBlocks,
    selectById: selectIntegrationBlockById,
    selectEntities: selectPartnerBlockEntities,
} = integrationBlocksAdapter.getSelectors((state) => state.integrationBlocks)

export const selectAllSubmittedIntegrationBlocks = (state) => {
    const allBlocks = selectAllIntegrationBlocks(state)
    return allBlocks.filter(
        (block) => block.partnerFields?.status === 'submitted'
    )
}

export const selectAllPublishedIntegrationBlocks = (state) => {
    const allBlocks = selectAllIntegrationBlocks(state)
    return allBlocks.filter(
        (block) => block.partnerFields?.status === 'published'
    )
}

export const selectIntegrationBlocksByAvailableIntegrations = (state) => {
    const integrationBlocks = selectAllPublishedIntegrationBlocks(state)
    const userIntegrations = selectAllActiveUserIntegrationsObj(state)

    const availableIntegrationBlocks = integrationBlocks.filter((block) => {
        return (
            userIntegrations[block.partnerFields.partnerId] &&
            userIntegrations[block.partnerFields.partnerId]?.enabled
        )
    })
    availableIntegrationBlocks.sort((a, b) => {
        const nameA =
            userIntegrations[a?.partnerFields?.partnerId]?.name?.toUpperCase()
        const nameB =
            userIntegrations[b?.partnerFields?.partnerId]?.name?.toUpperCase()

        if (nameA < nameB) {
            return -1
        }
        if (nameA > nameB) {
            return 1
        }
        return 0
    })

    return availableIntegrationBlocks.map((block) => ({
        ...block,
        id: block.id,
    }))
}

export const selectInactiveIntegrationsWithBlocks = (state) => {
    const integrationBlocks = selectAllIntegrationBlocks(state)
    const inactiveIntegrations = selectAllInactiveUserIntegrationsObj(state)

    const inactive = {}

    integrationBlocks.map((block) => {
        if (!block) return

        if (
            inactiveIntegrations[block?.partnerFields?.partnerId] &&
            !inactive[block?.partnerFields.partnerId]
        ) {
            inactive[block?.partnerFields?.partnerId] =
                inactiveIntegrations[block?.partnerFields?.partnerId]
        }
        return
    })
    const sortedInactive = Object.values(inactive).sort((a, b) =>
        (a?.title || '').localeCompare(b?.title || '')
    )

    return sortedInactive
}
export const selectIntegrationBlockEntities = (state) =>
    state.integrationBlocks.entities

export const { partnerBlockAddOne, partnerBlockUpdateOne } =
    integrationBlocksSlice.actions

export default integrationBlocksSlice.reducer
